import React from "react"
import SEO from "../../components/seo/seo"
import AnimateTexts from "../../components/animations/animate-texts"
import AnimateFade from "../../components/animations/animate-fade"
import CustomContainer from "../../components/general/contianer"
import InlineLink from "../../components/general/inline-link"
import Footer from "../../components/footer/footer"

const TermsAndConditions = ({transitionStatus}) => {

    return (
        <>
            <SEO description={""} title={"Privacy Policy"} pathname={"/privacy"}/>
            <section className={"privacy_section bg-background-dark py-12 sm:py-32"} style={{ marginTop: "112px" }}>
                <div className={"flex justify-center"}>
                    <AnimateTexts className={`privacy_header`} transitionStatus={transitionStatus} delay={400}>
                        <h1 className={"font-bold font-sans text-3xl md:text-4xl lg:text-5xl 2xl:text-6xl text-center px-4 max-w-2xl"} data-text-animate={true}>
                            Shipping & Returns
                        </h1>
                        <p className={"font-medium font-sans text-s, opacity-60 text-center px-4 max-w-2xl mt-3"} data-text-animate={true}>
                            Effective 19 Dec. 2020
                        </p>
                    </AnimateTexts>
                </div>
            </section>
            <section className={"privacy_section_data py-12"}>
                <CustomContainer className={"flex justify-center"}>
                    <div className={"max-w-2xl font-sans text-sm"}>
                        <h2 className={"font-bold text-lg capitalize"}>
                            1. SHIPPING
                        </h2>
                        <br/>
                        <ul>
                            <li>We offer a free shipping for orders above a minimum order amount which is ₹595 while there is a flat ₹95 charge on all orders below ₹595.</li>
                            <br/>
                            <li>We offer both Pre-paid and Cash on Delivery shipping.</li>
                            <br/>
                            <li>We ship Monday to Saturday, excluding public holidays. Transit time to locations depends on courier loads, local conditions and a variety of other factors, although we estimate a 3-5 day transit time in most cases.</li>
                            <br/>
                            <li>While we shall strive to ship all items in your order together, this may not always be possible due to product characteristics, or availability.</li>
                            <br/>
                            <li>Each order may be shipped only to a single destination address specified at the time of payment for that order. If you wish to ship products to different addresses, you will have to place multiple orders.</li>
                            <br/>
                            <li>Please note all items (including gifts) will be shipped with an invoice mentioning the price, as per Indian Tax Regulations.</li>
                        </ul>
                        <br/>
                        <br/>
                        <br/>
                        <h2 className={"font-bold text-lg capitalize"}>
                            2. CANCELLATION
                        </h2>
                        <br/>
                        <ul>
                            <li>Orders once placed can be cancelled before they have been shipped by reaching out to us at care@groomd.co.in. If you cancel it in time, we will refund the full amount back into your account if the order was prepaid, after the cancellation request is duly processed by us.</li>
                            <br/>
                            <li>In case the cancellation has been processed after the order has been shipped already, please do not accept the delivery of the order. Once we receive the products back and verify the packaging/condition, we will refund the money.</li>
                        </ul>
                        <br/>
                        <br/>
                        <br/>
                        <h2 className={"font-bold text-lg capitalize"}>
                            3. RETURNS & REFUNDS
                        </h2>
                        <br/>
                        We hope you love our products as much as we do and never need this page! But, if you’re dissatisfied for any reason, here is our return and refund policy.
                        <br/>
                        <br/>

                        Our shipments go through rigorous quality check processes before they leave our warehouse. However in the rare case that your product is damaged during shipment or transit, you can request for a return.
                        <br/>
                        <br/>

                        Groomd offers its customers an Easy return policy, wherein you can raise a return request of a product within 4 business days of its delivery.
                        <br/>
                        <br/>

                        If you have received an item in a damaged/defective condition or have been sent a wrong product, you can follow a few simple steps to initiate your return within 4 business days of receiving the order:
                        <br/>
                        <br/>

                        <ol style={{ listStyleType: "number", marginLeft: "20px" }}>
                            <li> Contact our Customer Support team via email (care@groomd.co.in) within 4 business days of receiving the order.</li>
                            <br/>
                            <li> Provide us with your order ID details and your request to return your order. Kindly email an image of the product and the invoice for our reference.</li>
                            <br/>
                            <li> We will pick up the products within 2-4 business days. We will initiate the Groomd credits process only if the products are received by us in their original packaging with their seals, labels and barcodes intact.</li>
                        </ol>
                        <br/>
                        <br/>
                        <i>Conditions</i>
                        <ul style={{ listStyleType: "circle", marginLeft: "20px" }}>
                            <li>Due to hygiene reasons we cannot accept returns on our products.</li>
                            <br/>
                            <li>We only accept returns in case of damaged/defective condition of the product or have been sent a wrong product.</li>
                            <br/>
                            <li>Refund shall be processed only as Groomd Credits. Credits can be used on our official website www.groomd.co.in, and credits given as refund never expire.</li>
                            <br/>
                            <li>Groomd men’s club points will be taken back once the return is processed.</li>
                        </ul>
                    </div>
                </CustomContainer>
            </section>
            <Footer transitionStatus={transitionStatus}/>

        </>
    )

}

export default TermsAndConditions